<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-col lg="8" class="p-0">
        <img :src="imgUrl" style="width: 100%; object-fit: cover; max-height: 100vh" alt="" />
      </b-col>
      <!-- Register-->
      <b-col lg="4" class="d-flex auth-bg side-form">
        <div class="mt-5 p-2 d-flex flex-column w-100" style="height: fit-content">
          <b-card-title
            title-tag="h2"
            style="margin-top: 5em"
            class="font-weight-bold mb-1 text-center"
          >
            <b-img fluid class="login-logo" :src="logoUrl" alt="Register image" />
            <span class="login-badge">
              <small>{{ $t('for') }}&nbsp;</small>
              <strong style="font-weight: 700">{{ $t('business') }}</strong>
            </span>
            <!--            Adventure starts here 🚀-->
          </b-card-title>
          <b-card-text class="mb-1">
            {{ $t('Welcome to') }}<strong> toddl.co</strong><br />
            {{ $t('the smart software for exceptional educators!') }}
          </b-card-text>
          <div v-if="!nextStep && !newSaaSHasSignupsEnabled" class="">
            <validation-observer ref="registerForm">
              <b-form class="auth-register-form mt-2">
                <!-- Business name -->
                <b-form-group :label="$t('Business name')" label-for="business_name">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Business name')"
                    rules="required"
                  >
                    <b-form-input
                      id="business_name"
                      v-model="formData.business_name"
                      name="business_name"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Business name')"
                    />
                    <p v-if="errors[0]" class="text-danger">
                      {{ $t('this field is required', { fieldName: $t('Business name') }) }}
                    </p>
                  </validation-provider>
                </b-form-group>

                <!-- username -->
                <b-form-group :label="$t('Name')" label-for="name">
                  <validation-provider #default="{ errors }" :name="$t('Name')" rules="required">
                    <b-form-input
                      id="name"
                      v-model="formData.name"
                      name="name"
                      :state="errors.length > 0 ? false : null"
                      :placeholder="$t('Name')"
                    />
                    <p v-if="errors[0]" class="text-danger">
                      {{ $t('this field is required', { fieldName: $t('Name') }) }}
                    </p>
                  </validation-provider>
                </b-form-group>

                <!-- email -->
                <b-form-group :label="$t('Email')" label-for="register-email">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Email')"
                    rules="required|email"
                  >
                    <b-form-input
                      id="register-email"
                      v-model="formData.email"
                      name="register-email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="you@gmail.com"
                    />
                    <p v-if="errors[0] && errors[0].match('email')" class="text-danger">
                      {{ $t('valid email', { fieldName: $t('Email') }) }}
                    </p>
                    <p v-if="errors[0] && !errors[0].match('email')" class="text-danger">
                      {{ $t('this field is required', { fieldName: $t('Email') }) }}
                    </p>
                  </validation-provider>
                </b-form-group>

                <!-- phone -->
                <b-form-group :label="$t('Phone')" label-for="phone">
                  <validation-provider #default="{ errors }" :name="$t('Phone')" rules="required">
                    <b-form-input
                      id="phone"
                      v-model="formData.phone"
                      name="phone"
                      :state="errors.length > 0 ? false : null"
                      placeholder="+34*******"
                    />
                    <p v-if="errors[0]" class="text-danger">
                      {{ $t('this field is required', { fieldName: $t('Phone') }) }}
                    </p>
                  </validation-provider>
                </b-form-group>

                <!-- password -->
                <b-form-group label-for="password" :label="$t('Password')">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Password')"
                    vid="Password"
                    rules="required|password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="password"
                        v-model="formData.password"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false : null"
                        name="password"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <p v-if="errors[0] && errors[0].match('one uppercase')" class="text-danger">
                      {{ $t('password error', { fieldName: $t('Password') }) }}
                    </p>
                    <p v-if="errors[0] && !errors[0].match('one uppercase')" class="text-danger">
                      {{ $t('this field is required', { fieldName: $t('Password') }) }}
                    </p>
                  </validation-provider>
                </b-form-group>
                <!-- password confirmation-->
                <b-form-group
                  label-for="password-confirmation"
                  :label="$t('Password confirmation')"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Password confirmation')"
                    rules="required|confirmed:Password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="password-confirmation"
                        v-model="formData.password_confirmation"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        :state="errors.length > 0 ? false : null"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          :icon="passwordToggleIcon"
                          class="cursor-pointer"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <p v-if="errors[0] && errors[0].match('not match')" class="text-danger">
                      {{ $t('confirm password error', { fieldName: $t('Password confirmation') }) }}
                    </p>
                    <p v-if="errors[0] && !errors[0].match('not match')" class="text-danger">
                      {{ $t('this field is required', { fieldName: $t('Password confirmation') }) }}
                    </p>
                  </validation-provider>
                </b-form-group>
                <b-form-group :label="$t('Language')" label-for="lang">
                  <div class="lang-wrapper">
                    <span
                      :class="'label-lang ' + (formData.lang === 'es' ? 'active' : '')"
                      @click="setLang('es')"
                      >Castellano</span
                    >
                    <span
                      :class="'label-lang ' + (formData.lang === 'en' ? 'active' : '')"
                      @click="setLang('en')"
                      >English</span
                    >
                  </div>
                </b-form-group>
                <b-form-group class="mt-2">
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Read terms')"
                    rules="required"
                  >
                    <b-form-checkbox
                      id="register-privacy-policy"
                      v-model="readTerms"
                      name="checkbox-1"
                      :state="errors.length > 0 || readTerms === false ? false : null"
                    >
                      {{ $t('I agree to the') }}
                      <a href="https://toddl.co/es/terms-of-service" target="_blank">
                        {{ $t('privacy policy & terms of service') }}
                      </a>
                    </b-form-checkbox>
                    <p v-if="errors.length > 0 || readTerms === false" class="text-danger">
                      {{ $t('Read terms') }}
                    </p>
                  </validation-provider>
                </b-form-group>

                <b-form-group class="mt-2">
                  <b-form-checkbox
                    id="receive-comms"
                    v-model="formData.receive_comms"
                    name="checkbox-2"
                  >
                    {{ $t('receive_comms') }}
                  </b-form-checkbox>
                </b-form-group>

                <b-button
                  variant="primary"
                  block
                  class="ml-auto mr-auto mt-3 w-50"
                  :disabled="loading"
                  type="submit"
                  @click.prevent="validationForm"
                >
                  <b-spinner v-if="loading" small />
                  {{ loading ? 'Loading...' : $t("Let's Go") }}
                </b-button>
              </b-form>
            </validation-observer>

            <p class="text-center mt-2">
              <span>{{ $t('Already have an account?') }}</span>
              <b-link :to="{ name: 'auth-login' }">
                <span class="text-underline">&nbsp;{{ $t('Sign in instead') }}</span>
              </b-link>
            </p>

            <p class="text-center mt-2 p-4 alternative-login">
              <span>{{ $t('Are you a family looking to book activities?') }}</span> <br />
              <a href="https://toddl.co/sign-up" class="btn btn-outline">{{
                $t('Register as a parent')
              }}</a>
            </p>
          </div>
          <div v-if="nextStep" class="">
            <p>
              {{ $t('Please check your email to verify your account') }}
            </p>
            <b-button
              variant="primary"
              block
              class="ml-auto mr-auto mt-3 w-50"
              :disabled="timer !== 0 || loading"
              @click.prevent="resendEmail"
            >
              <b-spinner v-if="loading" small />
              {{ $t('Resend email') }} {{ timer > 0 ? timer : '' }}
            </b-button>
          </div>
          <div v-if="newSaaSHasSignupsEnabled">
            <p class="new_platform_register alternative-login">
              <span>{{ $t('signups_disabled_for_legacy_dashboard') }}</span> <br />
              <a :href="`${newSaaSHost}/signup`" class="btn btn-outline">{{
                $t('new_platform_signup_cta')
              }}</a>
            </p>
          </div>
          <!-- form -->
        </div>
      </b-col>

      <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BRow,
  BCol,
  BLink,
  BButton,
  BSpinner,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BImg,
  BCardTitle,
  BCardText,
  BFormSelect,
} from 'bootstrap-vue'
import { required, email } from '@/libs/validations'
import { togglePasswordVisibility } from '@/mixins/togglePasswordVisibility'
import apiService from '@/services/shared/http/apiService'

export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BSpinner,
    BFormCheckbox,
    BFormGroup,
    BFormInput,

    BInputGroup,
    BInputGroupAppend,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      api: new apiService(),
      readTerms: '',
      imgUrl: require('@/assets/images/provider/provider_reg.jpg'),
      logoUrl: require('@/assets/images/logo/toddl.co_logo white.svg'),
      formData: {
        name: '',
        business_name: '',
        email: '',
        lang: this.$route.query.lang || 'es',
        password: '',
        password_confirmation: '',
        phone: '',
        receive_comms: 0,
      },
      localeOptions: [
        { label: 'Castellano', value: 'es-ES' },
        { label: 'English', value: 'en-EN' },
      ],
      loading: false,
      required,
      email,
      nextStep: false,
      timer: 0,
      newSaaSHost: process.env.VUE_APP_NEW_SAAS_URL,
      newSaaSHasSignupsEnabled: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  watch: {
    timer: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timer--
          }, 1000)
        }
      },
    },
  },
  mounted() {
    if (this.$route.query?.lang === 'en' || this.$route.query?.lang === 'es') {
      localStorage.setItem('locale', this.$route.query.lang)
      this.$store.commit('appConfig/SET_LANG', this.$route.query.lang)
      this.$i18n.locale = this.$route.query.lang
    } else {
      localStorage.setItem('locale', 'es')
      this.$store.commit('appConfig/SET_LANG', 'es')
      this.$i18n.locale = 'es'
    }

    this.$posthog.onFeatureFlags(() => {
      if (this.$posthog.isFeatureEnabled('new-platform-signup')) {
        this.newSaaSHasSignupsEnabled = true
      }
    })
  },
  methods: {
    setLang(str) {
      this.formData.lang = str
      this.$i18n.locale = str
    },
    validationForm() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.loading = true

          this.formData.lang = `${this.formData.lang}-${this.formData.lang.toUpperCase()}`
          this.api.POST('users', this.formData).then(
            res => {
              this.loading = false
              this.nextStep = true
              this.timer = 59
              this.$posthog.identify(res.id.toString(), {
                email: res.email,
                name: res.name,
                provider: res.business_name,
                plan: res.plan,
                period: res.payment_frequency,
                trial_ends_at: res.trial_ends_at,
              })
              this.$posthog.capture('Sign up', { email: this.formData.email })
            },
            error => {
              console.log(error)
              this.loading = false
            }
          )
        }
      })
    },
    resendEmail() {
      this.loading = true
      this.api.POST(`resend-email-verification?email=${this.formData.email}`).then(
        res => {
          this.loading = false
          this.timer = 60
        },
        err => {
          this.loading = false
          console.log(err)
        }
      )
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/vue/pages/page-auth.scss';

.side-form {
  max-height: 100vh;
  overflow: auto;
}

@media (min-width: 1740px) {
  .side-form {
    align-items: center;
  }
}

@media (max-width: 768px) {
  .side-form {
    max-height: unset;
  }
}
.new_platform_register {
  border: none;
}
.new_platform_register span {
  font-weight: 400;
}
</style>
